"use client";
import { datadogRum, DefaultPrivacyLevel } from "@datadog/browser-rum";
import { useReportWebVitals } from "next/web-vitals";
import type { NextWebVitalsMetric } from "next/app";
import { useEffect } from "react";

import { isProduction, isStaging } from "./utils/environment";

const shouldTrackToDatadog = isProduction || isStaging();

const vitalsMapper: { [key: string]: string } = {
  // Length of time it takes for the page to start and finish hydrating (in ms)
  "Next.js-hydration": "nextJsHydration",
  // Length of time it takes for a page to start rendering after a route change (in ms)
  "Next.js-route-change-to-render": "nextJsRouteChangeToRender",
  // Length of time it takes for a page to finish render after a route change (in ms)
  "Next.js-render": "nextJsRender",

  // Web Vitals - https://web.dev/vitals/
  CLS: "cumulativeLayoutShift",
  FCP: "firstContentfulPaint",
  FID: "firstInputDelay",
  LCP: "largestContentfulPaint",
  TTFB: "timeToFirstByte",
};

// Add this flag at the module level, outside the component
let isInitialized = false;

export function DatadogInit() {
  useReportWebVitals((metric: NextWebVitalsMetric) => {
    const mappedName = vitalsMapper[metric.name] || metric.name;

    if (!shouldTrackToDatadog) {
      // eslint-disable-next-line no-console
      console.info(
        `Datadog RUM: would have tracked web vital ${mappedName} - ${metric.value}.`,
      );
      return;
    }

    datadogRum.addAction(`Web Vital: ${mappedName}`, {
      [mappedName]: metric.value,
    });
  });

  useEffect(() => {
    // Prevent initialization if already done
    if (isInitialized) {
      return;
    }

    const applicationId = process.env.NEXT_PUBLIC_DATADOG_APP_ID;
    const clientToken = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN;

    if (!applicationId || !clientToken) {
      // eslint-disable-next-line no-console
      console.info(
        "Datadog RUM not initialized: missing applicationId or clientToken.",
      );
      return;
    }

    if (!shouldTrackToDatadog) {
      isInitialized = true;
      // eslint-disable-next-line no-console
      console.info(
        "Datadog RUM not initialized: not in a production or staging environment.",
      );
      return;
    }

    try {
      datadogRum.init({
        applicationId,
        clientToken,
        traceSampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        service: "canvas",
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        defaultPrivacyLevel: DefaultPrivacyLevel.ALLOW,
        version: process.env.NEXT_PUBLIC_COMMIT_SHA,
        allowedTracingUrls: [
          {
            match: /https:\/\/(.*\.)?zapier(-staging)?\.com/,
            propagatorTypes: ["tracecontext", "datadog"],
          },
          {
            match: window.origin,
            propagatorTypes: ["tracecontext", "datadog"],
          },
        ],
        env: process.env.VERCEL_ENV || process.env.NODE_ENV,
        enableExperimentalFeatures: ["feature_flags"],
      });
      isInitialized = true;
    } catch (error) {
      console.error("Failed to initialize Datadog RUM:", error);
    }
  }, []);

  return null;
}
